<div class="container home">
  <div class="d-flex justify-content-center">
    <div (keydown.ArrowLeft)="previousDay()" (keydown.ArrowRight)="nextDay()">
      <mat-icon class="select-day-icon" aria-hidden="false" (click)="previousDay()">chevron_left</mat-icon>
      <mat-form-field class="reservation-form-full-width" appearance="fill">
        <mat-label>Fecha</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="selectedDay" (dateChange)="changeDay('change', $event)">
      </mat-form-field>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-icon class="select-day-icon" aria-hidden="false" (click)="nextDay()">chevron_right</mat-icon>
    </div>
  </div>
</div>

<div class="home-spinner" *ngIf="isLoading"><mat-spinner></mat-spinner></div>

<mat-tab-group class="reservation-tabs-container">
  <mat-tab label="Plano">
    <app-reservations-day [reservations]="reservations" [selectedDay]="selectedDay.value"></app-reservations-day>
  </mat-tab>

  <mat-tab label="Listado">
    <div class="container">
      <div class="row justify-content-md-center mt-2">
        <div class="col-md-10">
          <table class="table">
            <thead>
            <td>Nº</td>
            <td>Hamacas</td>
            <td>Nombre</td>
            <td>Pagado</td>
            </thead>
            <tbody>
              <tr *ngFor="let reservation of reservations">
                <td>{{ reservation.id }}</td>
                <td>{{ reservation.hammocks.join(', ') }}</td>
                <td>{{ reservation.name }}</td>
                <td>{{ reservation.paid ? 'Si' : 'No' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </mat-tab>


</mat-tab-group>
