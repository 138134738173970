export class KayakReservation {
  id: number | null = null;
  date: Date = new Date();

  name: string = '';
  phone: string = '';
  dni: string = '';
  email: string = '';
  comments: string = '';

  start: null | string | Date = new Date();
  end: null | string | Date = new Date();

  numberOfKayaks: number = 0;
  numberOfDoubleKayaks: number = 0;
  numberOfPadels: number = 0;

  paid: boolean = false;
  price: number | undefined;
  payType: string = '';
}
