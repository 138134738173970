import { Component, ViewChild } from '@angular/core';
import { MatCalendar, MatCalendarCellClassFunction, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Reservation } from '../models/reservation.model';
import { HammockDisplay } from '../models/hammock-display.model';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-reservation-component',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})

@Injectable()
export class ReservationComponent {
  public readonly format = 'd/L/yyyy';
  public reservation = new Reservation();
  public hammockDisplayList: HammockDisplay[] = [];
  public hammocksAlreadyUsed: string[] = [];
  id: any;
  @ViewChild(MatCalendar) calendar!: MatCalendar<Date>;

  constructor(private http: HttpClient,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private dateAdapter: DateAdapter<Date>,
    private router: Router
  ) {
    http.get('Hammocks').subscribe(result => {
      let resultArray = result as any[];
      resultArray.forEach(x => {
        this.hammockDisplayList.push(new HammockDisplay(x.label, x.row, x.column));
      })
    });
  }

  ngOnInit() {
    this.dateAdapter.setLocale('es');
    this.dateAdapter.getFirstDayOfWeek = () => { return 1; };

    this.route.params.subscribe((params: Params) => {
      let reservationId = params['reservationId'];
      if (reservationId != null) {
        this.http.get('Reservations/' + reservationId).subscribe(reservation => {
          let reservationParsed = reservation as Reservation;
          this.reservation = reservationParsed;
          this.getHammocksAlreadyInUse();
          this.calendar.updateTodaysDate();
        });
      }
    });
  }

  public isValid(): boolean {
    if (this.reservation.name.trim() == '') {
      return false;
    }

    if (this.reservation.days.length == 0) {
      return false;
    }

    if (this.reservation.hammocks.length == 0) {
      return false;
    }

    return true;
  }

  public createReservation() {
    if (this.reservation.days.length == 0) {
      this.snackbar.open('No hay ninguna fecha seleccionada', 'Error', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    }

    this.http.post('Reservations', this.reservation).subscribe(reservation => {
      let reservationParsed = reservation as Reservation;
      this.reservation = reservationParsed;
      this.snackbar.open('Reserva ' + reservationParsed.id + ' actualizada con éxito', 'OK', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    });
  }

  isSelected = (event: Date): MatCalendarCellCssClasses => {
    const date = this.getDateFormatted(event);

    return this.reservation.days.find(x => x == date) ? 'selected' : '';
  };

  selectDate(event: any, calendar: any) {
    const date = this.getDateFormatted(event);
    const index = this.reservation.days.findIndex(x => x == date);
    if (index < 0) {
      this.reservation.days.push(date)
    } else {
      this.reservation.days.splice(index, 1);
    }

    calendar.updateTodaysDate();

    this.getHammocksAlreadyInUse();
  }

  private getHammocksAlreadyInUse() {
    let params = new HttpParams();
    this.reservation.days.forEach(x => {
      params = params.append('days', x);
    });

    this.http.get("Reservations/HammocksInUse", {
      params: params
    }).subscribe(hammocksInUse => {
      this.hammocksAlreadyUsed = hammocksInUse as string[];
    });
  }

  getDateFormatted(date: Date) {
    return date.getFullYear() + '-' + ("00" + (date.getMonth() + 1)).slice(-2) + '-' + ("00" + date.getDate()).slice(-2);
  }

  deleteReservation() {
    if (confirm('¿Estas seguro de que quieres eliminar la reserva?')) {
      this.http.delete('Reservations/' + this.reservation.id).subscribe(() => {
        this.snackbar.open('Reserva eliminada', 'OK', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.router.navigate(['']);
      });
    }
  }
}
