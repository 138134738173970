export class HammockDisplay {
  label: string = '';
  row: number = 0;
  column: number = 0;

  constructor(label: string, row: number, column: number) {
    this.label = label;
    this.row = row;
    this.column = column;
  }
}
