<div class="reservations-day-container">

  <div>
    <div class="grid" style="display: grid">
      <div *ngFor="let hammockDisplay of hammockDisplayList; let i = index" [ngStyle]="
         {'grid-column-start': hammockDisplay.column,
         'grid-column-end': hammockDisplay.column + 1,
         'grid-row-start': hammockDisplay.row,
         'grid-row-end': hammockDisplay.row + 1,
         'background-color': getHammockColor(hammockDisplay.label, i),
         'margin-right': hammockDisplay.column % 2 == 0 ? '6px' : '',
         'position': 'relative'
         }"
           class="reservations-day-hammock"
           [matTooltip]="getTooltipText(hammockDisplay.label)"
           matTooltipTouchGestures="off"
           (click)="clickReservation(hammockDisplay.label)">
        {{ hammockDisplay.label}}
        <mat-icon class="reservation-day-icon-paid" *ngIf="isFirstHammockInReservation(hammockDisplay.label)" [ngClass]="{'reservation-day-icon-paid-true' : getReservationPaid(hammockDisplay.label)}">paid</mat-icon>
        <div class="reservations-day-hammock-name">
          {{ getReservationName(hammockDisplay.label) }}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row justify-content-md-center">
    <div class="col-md-4 mt-3">
      <button [disabled]="!isValid()" class="btn btn-primary w-100" (click)="createQuickReservation()">Crear reserva rápida</button>
    </div>
  </div>
</div>
