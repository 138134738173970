<div class="container">
  <form class="reservation-form">
    <mat-tab-group class="reservation-tabs-container">
      <mat-tab label="Datos de contacto">

        <div class="container">

          <div class="row justify-content-md-center mt-2">
            <div class="col-md-6">

              <mat-form-field class="reservation-form-full-width" appearance="fill">
                <mat-label>Nombre</mat-label>
                <input matInput placeholder="" name="name" [(ngModel)]="kayakReservation.name">
              </mat-form-field>

              <mat-form-field class="reservation-form-full-width" appearance="fill">
                <mat-label>Teléfono</mat-label>
                <input matInput placeholder="" name="phone" [(ngModel)]="kayakReservation.phone">
              </mat-form-field>

              <mat-form-field class="reservation-form-full-width" appearance="fill">
                <mat-label>Dni</mat-label>
                <input matInput placeholder="" name="dni" [(ngModel)]="kayakReservation.dni">
              </mat-form-field>

              <mat-form-field class="reservation-form-full-width" appearance="fill">
                <mat-label>Email</mat-label>
                <input matInput placeholder="" name="email" [(ngModel)]="kayakReservation.email">
              </mat-form-field>

              <mat-form-field class="reservation-form-full-width" appearance="fill">
                <mat-label>Kayaks simples</mat-label>
                <mat-select [(value)]="kayakReservation.numberOfKayaks">
                  <mat-option *ngFor="let number of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]" [value]="number">{{ number }}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="reservation-form-full-width" appearance="fill">
                <mat-label>Kayaks dobles</mat-label>
                <mat-select [(value)]="kayakReservation.numberOfDoubleKayaks">
                  <mat-option *ngFor="let number of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]" [value]="number">{{ number }}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="reservation-form-full-width" appearance="fill">
                <mat-label>Paddles surf</mat-label>
                <mat-select [(value)]="kayakReservation.numberOfPadels">
                  <mat-option *ngFor="let number of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]" [value]="number">{{ number }}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="reservation-form-full-width" appearance="fill">
                <mat-label>Inicio</mat-label>
                <input matInput type="datetime-local" placeholder="Inicio"
                       [ngModel]="kayakReservation.start | date:'yyyy-MM-ddTHH:mm'"
                       (ngModelChange)="kayakReservation.start = $event"
                       name="start" required>
              </mat-form-field>

              <mat-form-field class="reservation-form-full-width" appearance="fill">
                <mat-label>Fin</mat-label>
                <input matInput type="datetime-local" placeholder="Inicio"
                       [ngModel]="kayakReservation.end | date:'yyyy-MM-ddTHH:mm'"
                       (ngModelChange)="kayakReservation.end = $event"
                       name="end">
              </mat-form-field>

              <mat-form-field class="reservation-form-full-width" appearance="fill">
                <mat-checkbox [(ngModel)]="kayakReservation.paid" class="mb-2" name="paid">
                  Pagado <input matInput>
                </mat-checkbox>
              </mat-form-field>

              <mat-form-field class="reservation-form-full-width" appearance="fill">
                <mat-label>Precio</mat-label>
                <input matInput placeholder="" name="price" [(ngModel)]="kayakReservation.price">
              </mat-form-field>

              <mat-form-field class="reservation-form-full-width" appearance="fill">
                <mat-label>Forma de pago</mat-label>
                <mat-select [(value)]="kayakReservation.payType">
                  <mat-option></mat-option>
                  <mat-option value="Metálico">Metálico</mat-option>
                  <mat-option value="Tarjeta">Tarjeta</mat-option>
                  <mat-option value="Bizum">Bizum</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="reservation-form-full-width" appearance="fill">
                <mat-label>Comentarios</mat-label>
                <input matInput placeholder="" name="comments" [(ngModel)]="kayakReservation.comments">
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-tab>

    </mat-tab-group>
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-md-4">
          <button [disabled]="!isValid()" class="btn btn-primary w-100" (click)="createKayakReservation()">{{ kayakReservation.id == null ? 'Crear alquiler' : 'Actualizar alquiler' }}</button>
        </div>
      </div>

      <div class="row justify-content-md-center mt-3" *ngIf="kayakReservation.id != null">
        <div class="col-md-4">
          <button class="btn btn-danger w-100" (click)="deleteKayakReservation()">Eliminar alquiler</button>
        </div>
      </div>

    </div>

  </form>

</div>

