import { Component, Input } from '@angular/core';
import { JsonPipe } from '@angular/common';
import { HammockDisplay } from '../../models/hammock-display.model';
import { Reservation } from '../../models/reservation.model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-reservations-day',
  templateUrl: './reservations-day.component.html',
  styleUrls: ['./reservations-day.component.css']
})
export class ReservationsDayComponent {
  @Input() hammockDisplayList : HammockDisplay[] = [];
  @Input() reservations: Reservation[] = [];
  @Input() selectedDay: Date = new Date();

  distinctColors = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#800000', '#aaffc3', '#808000', '#000075'];
  selectedHammocks: string[] = [];

  constructor(private http: HttpClient, private snackbar: MatSnackBar, private router: Router) {
    http.get('Hammocks').subscribe(result => {
      let resultArray = result as any[];
      resultArray.forEach(x => {
        this.hammockDisplayList.push(new HammockDisplay(x.label, x.row, x.column));
      })
    });
  }

  getReservationForHammock(hammockLabel: string): Reservation | null {
    let reservation = this.reservations.find(x => x.hammocks.indexOf(hammockLabel) != -1);    
    if (reservation != undefined) {
      return reservation;
    }

    return null;
  }

  getTooltipText(hammockLabel: string): string {
    let reservation = this.getReservationForHammock(hammockLabel);
    if (reservation != null) {
      return reservation.id + ' - ' + reservation.name;
    }

    return '';
  }

  clickReservation(hammockLabel: string) : void {
    let reservation = this.reservations.find(x => x.hammocks.indexOf(hammockLabel) != -1);
    if (reservation != undefined) {
      this.router.navigate(['reservation', reservation.id]);
    } else {
      if (this.selectedHammocks.indexOf(hammockLabel) != -1) {
        this.selectedHammocks.splice(this.selectedHammocks.indexOf(hammockLabel), 1);
      } else {
        this.selectedHammocks.push(hammockLabel);
      }
    }  
  }

  openReservation(hammockLabel: string) : void {
    let reservation = this.reservations.find(x => x.hammocks.indexOf(hammockLabel) != -1);
    if (reservation != undefined) {
      this.router.navigate(['reservation', reservation.id]);
    }
  }

  createQuickReservation(): void {
    if (this.isValid()) {
      let reservation = new Reservation();
      reservation.name = 'Reserva rápida';
      reservation.days.push(this.getDateFormatted(this.selectedDay));
      reservation.hammocks = this.selectedHammocks;

      this.http.post('Reservations', reservation).subscribe(reservation => {
        let reservationParsed = reservation as Reservation;
        this.snackbar.open('Reserva rápida creada', 'OK', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.reservations.push(reservationParsed);
        this.selectedHammocks = [];
      });
    }
  }

  isValid(): boolean {
    return this.selectedHammocks.length > 0;

    return false;
  }

  isInUse(hammockLabel: string): boolean {
    return this.reservations.some(x => x.hammocks.indexOf(hammockLabel) != -1);
  }

  isSelected(hammockLabel: string) {
    return this.selectedHammocks.indexOf(hammockLabel) != -1;
  }

  getHammockColor(hammockLabel: string, index: number) {
    if (this.isSelected(hammockLabel))
      return '#80808880';

    let reservation = this.getReservationForHammock(hammockLabel);
    if (reservation != null) {
      return this.distinctColors[reservation.id as number % this.distinctColors.length] + '80';
      // return 'rgba(255,0,' + (reservation.id as number % 20) * 25 + ',0.5)'
    }

    return '';
  }

  getReservationName(hammockLabel: string) : string {
    let reservation = this.reservations.find(x => x.hammocks.length > 0 && x.hammocks[0] == hammockLabel);    
    if (reservation != null) {
      return reservation.name;
    }

    return '';
  }

  isFirstHammockInReservation(hammockLabel: string): boolean {
    let reservation = this.reservations.find(x => x.hammocks.length > 0 && x.hammocks[0] == hammockLabel);
    if (reservation != null) {
      return true;
    }

    return false;
  }

  getReservationPaid(hammockLabel: string) : boolean {
    let reservation = this.reservations.find(x => x.hammocks.length > 0 && x.hammocks[0] == hammockLabel);
    if (reservation != null) {
      return reservation.paid;
    }

    return false;
  }

  getDateFormatted(date: Date) : string {
    return date.getFullYear() + '-' + ("00" + (date.getMonth() + 1)).slice(-2) + '-' + ("00" + date.getDate()).slice(-2);
  }
}
