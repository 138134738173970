<div class="hammock-picker-container">

  <div>
    <div class="grid" style="display: grid">
      <div *ngFor="let hammockDisplay of hammockDisplayList" [ngStyle]="
         {'grid-column-start': hammockDisplay.column,
         'grid-column-end': hammockDisplay.column + 1,
         'grid-row-start': hammockDisplay.row,
         'grid-row-end': hammockDisplay.row + 1,
         'background-color': isSelected(hammockDisplay.label) ? 'rgba(0,255,0,0.5)' : isInUse(hammockDisplay.label) ? 'rgba(255,0,0,0.5)' : '',
         'margin-right': hammockDisplay.column % 2 == 0 ? '6px' : ''
         }" (click)="toggleHammock(hammockDisplay.label)"
           class="hammock-picker-hammock">
        {{ hammockDisplay.label}}
      </div>
    </div>
  </div>
</div>
