<div class="container">
  <form class="reservation-form">
    <mat-tab-group class="reservation-tabs-container">
      <mat-tab label="Datos de contacto">

        <div class="container">

          <div class="row justify-content-md-center mt-2">
            <div class="col-md-6">

              <mat-form-field class="reservation-form-full-width" appearance="fill">
                <mat-label>Nombre</mat-label>
                <input matInput placeholder="" name="name" [(ngModel)]="reservation.name">
              </mat-form-field>

              <mat-form-field class="reservation-form-full-width" appearance="fill">
                <mat-label>Teléfono</mat-label>
                <input matInput placeholder="" name="phone" [(ngModel)]="reservation.phone">
              </mat-form-field>

              <mat-form-field class="reservation-form-full-width" appearance="fill">
                <mat-label>Email</mat-label>
                <input matInput placeholder="" name="email" [(ngModel)]="reservation.email">
              </mat-form-field>

              <mat-checkbox [(ngModel)]="reservation.paid" class="mb-2" name="paid">
                Pagado
              </mat-checkbox>

              <mat-form-field class="reservation-form-full-width" appearance="fill">
                <mat-label>Precio</mat-label>
                <input matInput placeholder="" name="price" [(ngModel)]="reservation.price">
              </mat-form-field>

              <mat-form-field class="reservation-form-full-width" appearance="fill">
                <mat-label>Mesa</mat-label>
                <input matInput placeholder="" name="table" [(ngModel)]="reservation.table">
              </mat-form-field>

              <mat-form-field class="reservation-form-full-width" appearance="fill">
                <mat-label>Comentarios</mat-label>
                <input matInput placeholder="" name="comments" [(ngModel)]="reservation.comments">
              </mat-form-field>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Fechas">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-md-4">
              <div (click)="$event.stopPropagation()">
                <mat-calendar #calendar
                              (selectedChange)="selectDate($event,calendar)"
                              [dateClass]="isSelected">
                </mat-calendar>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="Tumbonas">
        <app-hammock-picker [hammockDisplayList]="hammockDisplayList"
                            [selectedHammocks]="reservation.hammocks"
                            [hammocksAlreadyUsed]="hammocksAlreadyUsed"></app-hammock-picker>

      </mat-tab>

    </mat-tab-group>
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-md-4">
          <button [disabled]="!isValid()" class="btn btn-primary w-100" (click)="createReservation()">{{ reservation.id == null ? 'Crear reserva' : 'Actualizar reserva' }}</button>
        </div>
      </div>

      <div class="row justify-content-md-center mt-3" *ngIf="reservation.id != null">
        <div class="col-md-4">
          <button class="btn btn-danger w-100" (click)="deleteReservation()">Eliminar reserva</button>
        </div>
      </div>

    </div>

  </form>

</div>

