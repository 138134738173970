import { Component, Input } from '@angular/core';
import { JsonPipe } from '@angular/common';
import { HammockDisplay } from '../models/hammock-display.model';

@Component({
  selector: 'app-hammock-picker',
  templateUrl: './hammock-picker.component.html',
  styleUrls: ['./hammock-picker.component.css']
})
export class HammockPickerComponent {
  @Input() hammockDisplayList : HammockDisplay[] = [];
  @Input() selectedHammocks: string[] = [];
  @Input() hammocksAlreadyUsed: string[] = [];


  toggleHammock(hammockLabel: string) {
    if (this.isInUse(hammockLabel) && !this.isSelected(hammockLabel)) {
      return;
    }

    if (this.selectedHammocks.indexOf(hammockLabel) != -1) {
      this.selectedHammocks.splice(this.selectedHammocks.indexOf(hammockLabel), 1);
    } else {
      this.selectedHammocks.push(hammockLabel);
    }

    if (this.hammocksAlreadyUsed.indexOf(hammockLabel) != -1) {
      this.hammocksAlreadyUsed.splice(this.hammocksAlreadyUsed.indexOf(hammockLabel), 1);
    }
  }

  isSelected(hammockLabel: string) {
    return this.selectedHammocks.indexOf(hammockLabel) != -1;
  }

  isInUse(hammockLabel: string): boolean {
    return this.hammocksAlreadyUsed.indexOf(hammockLabel) != -1;
  }
}
