import { Component, ViewChild } from '@angular/core';
import { MatCalendar, MatCalendarCellClassFunction, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KayakReservation } from '../models/kayak-reservation.model';
import { HammockDisplay } from '../models/hammock-display.model';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-kayak-reservation-component',
  templateUrl: './kayak-reservation.component.html',
  styleUrls: ['./kayak-reservation.component.css']
})

@Injectable()
export class KayakReservationComponent {
  public readonly format = 'd/L/yyyy';
  public kayakReservation = new KayakReservation();
  public startValue = '';

  id: any;

  @ViewChild(MatCalendar) calendar!: MatCalendar<Date>;

  constructor(private http: HttpClient,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute,
    private dateAdapter: DateAdapter<Date>,
    private router: Router
  ) {}

  ngOnInit() {
    this.dateAdapter.setLocale('es');
    this.dateAdapter.getFirstDayOfWeek = () => { return 1; };

    this.route.params.subscribe((params: Params) => {
      let kayakReservationId = params['kayakReservationId'];
      if (kayakReservationId != null) {
        this.http.get('KayakReservations/' + kayakReservationId).subscribe(reservation => {
          let reservationParsed = reservation as KayakReservation;
          this.kayakReservation = reservationParsed;
        });
      } else {
        this.kayakReservation = new KayakReservation();
        this.kayakReservation.name = '';
        this.kayakReservation.start = new Date();
        this.startValue = this.kayakReservation.start.toISOString();

        this.kayakReservation.numberOfKayaks = 0;
        this.kayakReservation.numberOfDoubleKayaks = 0;
        this.kayakReservation.numberOfPadels = 0;

        this.kayakReservation.end = new Date();
        this.kayakReservation.end.setHours(this.kayakReservation.end.getHours() + 1);
      }
    });
  }

  public isValid(): boolean {
    if (this.kayakReservation.name.trim() == '') {
      return false;
    }

    return true;
  }

  public createKayakReservation() {

    this.kayakReservation.end = this.kayakReservation.end === '' ? null : this.kayakReservation.end;
    this.http.post('KayakReservations', this.kayakReservation).subscribe(kayakReservation => {
      let kayakReservationParsed = kayakReservation as KayakReservation;
      this.kayakReservation = kayakReservationParsed;
      this.snackbar.open('Alquiler ' + kayakReservationParsed.id + ' actualizado con éxito', 'OK', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    });
  }

  deleteKayakReservation() {
    if (confirm('¿Estas seguro de que quieres eliminar el alquiler?')) {
      this.http.delete('KayakReservations/' + this.kayakReservation.id).subscribe(() => {
        this.snackbar.open('Reserva eliminada', 'OK', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.router.navigate(['kayaks']);
      });
    }
  }

  getDateFormatted(date: Date | string) {
    if (typeof date === 'string') {
      return date;
    }

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const minutes = date.getMinutes();
    const hours = date.getHours();
    const seconds = date.getSeconds();

    const formattedDate = year + "-" + ("00" + (monthIndex + 1)).slice(-2) + "-" + ("00" + day).slice(-2) + "T" + ("00" + hours).slice(-2) + ":" + ("00" + minutes).slice(-2);

    return formattedDate;
    // return '2016-02-01T03:35';
  }

  changeStartDate(event: any) {
    this.kayakReservation.start = event;
  }
}
