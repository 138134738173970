export class Reservation {
  id: number | null  = null;
  name: string = '';
  phone: string = '';
  email: string = '';
  paid: boolean = false;
  price: number | undefined;
  table: string = '';
  comments: string = '';
  days: string[] = [];
  hammocks: string[] = [];
}
