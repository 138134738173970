import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { KayakReservation } from '../models/kayak-reservation.model';

@Component({
  selector: 'app-kayaks',
  templateUrl: './kayaks.component.html',
  styleUrls: ['./kayaks.component.css']
})
export class KayaksComponent {
  kayakReservations: KayakReservation[] = [];
  selectedDay: FormControl = new FormControl(new Date());
  isLoading: boolean = false;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowLeft':
        this.previousDay();
        break;
      case 'ArrowRight':
        this.nextDay();
    }
  }

  constructor(private http: HttpClient,
    private snackbar: MatSnackBar,
    private dateAdapter: DateAdapter<Date>,
    private router: Router) { }

  ngOnInit() {
    this.dateAdapter.setLocale('es');
    this.dateAdapter.getFirstDayOfWeek = () => { return 1; };
    this.refreshReservations();
  }

  private refreshReservations() {
    let params = new HttpParams().set('day', this.selectedDay.value.getFullYear() + '-' + (this.selectedDay.value.getMonth() + 1) + '-' + this.selectedDay.value.getDate());

    this.isLoading = true;
    this.http.get('KayakReservations', {
      params: params
    }).subscribe(kayakReservations => {
      this.kayakReservations = kayakReservations as KayakReservation[];
      this.isLoading = false;
    });
  }

  changeDay(type: string, event: MatDatepickerInputEvent<Date>) {
    this.selectedDay.setValue(event.value as Date);
    this.refreshReservations();
  }

  nextDay() {
    this.selectedDay.value.setDate(this.selectedDay.value.getDate() + 1);
    this.selectedDay.setValue(this.selectedDay.value);
    this.refreshReservations();
  }

  previousDay() {
    this.selectedDay.value.setDate(this.selectedDay.value.getDate() - 1);
    this.selectedDay.setValue(this.selectedDay.value);
    this.refreshReservations();
  }

  clickReservation(kayakReservationId: number | null): void {
    this.router.navigate(['kayak-reservation', kayakReservationId]);
  }

  createReservation(): void {
    this.router.navigate(['kayak-reservation']);
  }
}
