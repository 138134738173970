<div class="container home">
  <div class="d-flex justify-content-center">
    <div (keydown.ArrowLeft)="previousDay()" (keydown.ArrowRight)="nextDay()">
      <mat-icon class="select-day-icon" aria-hidden="false" (click)="previousDay()">chevron_left</mat-icon>
      <mat-form-field class="reservation-form-full-width" appearance="fill">
        <mat-label>Fecha</mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="selectedDay" (dateChange)="changeDay('change', $event)">
      </mat-form-field>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <mat-icon class="select-day-icon" aria-hidden="false" (click)="nextDay()">chevron_right</mat-icon>
    </div>
  </div>
</div>

<div class="home-spinner" *ngIf="isLoading"><mat-spinner></mat-spinner></div>

<mat-tab-group class="reservation-tabs-container">
  <mat-tab label="Listado">
    <div class="container">
      <div class="row justify-content-md-center mt-2">
        <div class="col-md-10">
          <table class="table">
            <thead>
              <tr>
                <th>Nº</th>
                <th>Nombre</th>
                <th>Kayaks/Paddle</th>
                <th>Inicio</th>
                <th>Fin</th>
                <th>Pagado</th>
                <th>Forma pago</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let kayakReservation of kayakReservations" (click)="clickReservation(kayakReservation.id)">
                <td>{{ kayakReservation.id }}</td>
                <td>{{ kayakReservation.name }}</td>
                <td>
                  {{ kayakReservation.numberOfKayaks > 0 ? kayakReservation.numberOfKayaks + ' kayak/s simple ' : '' }}
                  {{ kayakReservation.numberOfDoubleKayaks > 0 ? kayakReservation.numberOfDoubleKayaks + ' kayak/s dobles ' : '' }}
                  {{ kayakReservation.numberOfPadels > 0 ? kayakReservation.numberOfPadels + ' paddle surfs' : ''}}
                </td>
                <td>{{ kayakReservation.start | date: 'HH:mm' }}</td>
                <td>{{ kayakReservation.end | date: 'HH:mm' }}</td>
                <td>{{ kayakReservation.paid ? 'Si' : 'No' }}</td>
                <td>{{ kayakReservation.payType }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-md-4 mt-3">
          <button class="btn btn-primary w-100" (click)="createReservation()">Alquilar kayak</button>
        </div>
      </div>
    </div>

  </mat-tab>


</mat-tab-group>
