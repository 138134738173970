import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { ReservationComponent } from './reservation/reservation.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';

import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { NgxMultipleDatesModule } from 'ngx-multiple-dates';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // module import
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { HammockPickerComponent } from './components/hammock-picker.component';
import { ReservationsDayComponent } from './components/reservations-day/reservations-day.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomDateAdapter } from './utils/custom-date-adapter';
import { Platform } from '@angular/cdk/platform';
import { AboutComponent } from './about/about.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { KayaksComponent } from './kayaks/kayaks.component';
import { KayakReservationComponent } from './kayak-reservations/kayak-reservation.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    ReservationComponent,
    FetchDataComponent,
    HammockPickerComponent,
    ReservationsDayComponent,
    AboutComponent,
    KayaksComponent,
    KayakReservationComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'reservation', component: ReservationComponent },
      { path: 'reservation/:reservationId', component: ReservationComponent },
      { path: 'about', component: AboutComponent },
      { path: 'kayaks', component: KayaksComponent },
      { path: 'kayak-reservation', component: KayakReservationComponent },
      { path: 'kayak-reservation/:kayakReservationId', component: KayakReservationComponent },
    ]),
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    MatNativeDateModule, // any of the supported date adapter should be imported
    MatIconModule,
    NgxMultipleDatesModule,
    MatTabsModule,
    MatSnackBarModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDatepickerModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
